import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/store";

const dashboard = (path) => () =>
  import(`@/components/pages/Dashboard/${path}`).then((m) => m.default || m);
const routes = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: dashboard("Dashboard.vue"),

    children: [
      {
        path: "new-apply",
        name: "new-apply",
        component: dashboard("NewApplyView.vue"),
        meta: {
          auth: true,
        },
      },
      {
        path: "terms",
        name: "terms",
        component: dashboard("TermsView.vue"),
        meta: {
          auth: true,
        },
      },
      {
        path: "deals",
        name: "deals",
        component: dashboard("DealsView.vue"),
        meta: {
          auth: true,
        },
      },
      {
        path: "stats",
        name: "stats",
        component: dashboard("StatsView.vue"),
        meta: {
          auth: true,
        },
      },
      {
        path: "applies",
        name: "applies",
        component: dashboard("AppliesView.vue"),
        meta: {
          auth: true,
        },
      },
      {
        path: "profile",
        name: "profile",
        component: dashboard("Profile.vue"),
        meta: {
          auth: true,
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/views/NotFound.vue"),
  },
  {
    path: "/",
    redirect: "authentication",
  },
  {
    path: "/authentication",
    name: "authentication",
    component: () => import("@/views/Authentication.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const currentUser = store.state.user;
  const requireAuth = to.matched.some((itemObject) => itemObject.meta.auth);
  
  if (requireAuth) {
    if (!currentUser.id) {
      next("/");
    } else {
      next();
    }
  }

  next();
});

export default router;
